@import 'vars/mixins';

html,
body {
	margin: 0;
	padding: 0;
}
html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
* {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent; /* For some Androids */
}
img {
	border: none;
}
article,
main,
nav,
header,
footer,
aside {
	display: block;
}
pre {
	// white-space: pre-line;
}
pre > code {
	overflow-x: auto;
	max-width: 100%;
	display: block;
}
input,
textarea {
	max-width: 100%;
	box-sizing: border-box;
}
input[type='text'],
input[type='search'],
button {
	-webkit-appearance: none;
}
img,
video,
iframe {
	max-width: 100%;
}
blockquote {
	margin: 0;
}
button {
	cursor: pointer;
}

.uncollapse {
	@include uncollapse;
}
.uc {
	text-transform: uppercase;
}
.cf {
	@include clearfix;
}
.as {
	@include activeState;
}

.middle {
	display: inline-block;
	vertical-align: middle;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-left-hint {
	text-align: left;
	color: yellow;
	margin-top: 10px;
}
.text-center-hint {
	text-align: center;
	color: yellow;
	margin-top: 10px;
}
.text-right {
	text-align: left;
}
