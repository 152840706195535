@import 'vars';

.lit-header {
	padding: $space-sm 0;

	.right {
		position: absolute;
		right: $space;
		top: 35px;
	}
	.lit-link {
		@include link($white, $secondary);
	}
}

.lit-logo {
	margin-left: $space;
}
